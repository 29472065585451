import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import ImageUpload from "./ImageUpload";
function App() {
  const [visitorCount, setVisitorCount] = useState(42069);
  const [loadingSkills, setLoadingSkills] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [showSecretMessage, setShowSecretMessage] = useState(false);

  // Emoji rain effect
  useEffect(() => {
    const createEmoji = () => {
      const emoji = document.createElement("div");
      emoji.className = "emoji-rain";
      emoji.style.left = `${Math.random() * 100}vw`;
      emoji.style.animationDuration = `${Math.random() * 3 + 2}s`;
      emoji.innerHTML = ["🤪", "💻", "🚀", "✨", "🎮", "🤔", "🐛", "☕"][
        Math.floor(Math.random() * 8)
      ];
      document.querySelector(".App").appendChild(emoji);
      setTimeout(() => emoji.remove(), 5000);
    };

    const interval = setInterval(createEmoji, 3000);
    return () => clearInterval(interval);
  }, []);

  // Fake visitor counter
  useEffect(() => {
    const interval = setInterval(() => {
      setVisitorCount((prev) => prev + Math.floor(Math.random() * 10));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Fake loading state for skills
  const startFakeLoading = () => {
    setLoadingSkills(true);
    setTimeout(() => setLoadingSkills(false), 3000);
  };

  // Array of "skills" with funny descriptions
  const skills = [
    "Copy & Paste Engineering (Advanced)",
    "Stack Overflow Navigation",
    "Professional Googler",
    "Coffee to Code Conversion",
    "Bug Creation (Unintentional)",
    "Creative Excuse Generation",
    "Ctrl+Z Mastery",
    "React State Management (Sometimes)",
  ];

  return (
    <div className="App">
      <header className="App-header">
        {/* Visitor Counter */}
        <div
          className="rainbow-text"
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            fontSize: "16px",
          }}
        >
          Visitors: {visitorCount.toLocaleString()}
          <span style={{ fontSize: "12px" }}>(totally real)</span>
        </div>

        {/* Logo Section */}
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          onClick={() => setClickCount((prev) => prev + 1)}
          style={{ cursor: "pointer" }}
        />

        {/* Click Counter Easter Egg */}
        {clickCount > 0 && (
          <p className="rainbow-text" style={{ fontSize: "16px" }}>
            You've clicked the logo {clickCount} times.
            {clickCount > 10
              ? " You really like clicking things, huh? 🤔"
              : " Keep going..."}
          </p>
        )}

        <h1 className="glitch-text">
          Welcome to My Professional Portfolio Website
        </h1>

        {/* Skills Section */}
        <div style={{ margin: "20px 0" }}>
          <button
            className="App-link"
            style={{
              cursor: "pointer",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
            onClick={startFakeLoading}
          >
            {loadingSkills ? "Loading Skills..." : "View My Skills"}
          </button>

          {loadingSkills ? (
            <p>Loading... (or am I?)</p>
          ) : (
            <div style={{ margin: "20px 0" }}>
              {skills.map((skill, index) => (
                <p
                  key={index}
                  className="glitch-text"
                  style={{ fontSize: "16px", margin: "10px 0" }}
                >
                  {skill}
                </p>
              ))}
            </div>
          )}
        </div>

        {/* Secret Message */}
        <div
          onMouseEnter={() => setShowSecretMessage(true)}
          onMouseLeave={() => setShowSecretMessage(false)}
          style={{ margin: "20px 0", cursor: "pointer" }}
        >
          <p style={{ fontSize: "14px" }}>Hover here for a secret message...</p>
          {showSecretMessage && (
            <p className="rainbow-text">
              The real secret is the bugs we made along the way 🐛
            </p>
          )}
        </div>

        {/* Projects Section */}
        <div style={{ margin: "20px 0" }}>
          <h2 className="glitch-text">Recent Projects</h2>
          <ImageUpload /> {/* Add this line */}
          <p>• 404 Generator (100% success rate!)</p>
          <p>• Infinite Loop Creator (Please check your CPU)</p>
          <p>• Professional Coffee Consumer API</p>
        </div>

        {/* Contact Link */}
        <a
          className="App-link"
          href="https://github.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            window.alert(
              "Thanks for clicking! Unfortunately, I'm probably debugging right now... or procrastinating. Try carrier pigeon? 🐦.... \nor Linkedin: www.linkedin.com/in/davis-chun"
            );
          }}
        >
          Contact Me (if you dare)
        </a>
        {/* Footer */}
        <p style={{ fontSize: "14px", marginTop: "40px", opacity: 0.7 }}>
          © {new Date().getFullYear()} - Built with{" "}
          {clickCount > 10 ? "questionable" : "minimal"} effort and lots of
          Stack Overflow
        </p>
        <div style={{ fontSize: "11px", opacity: 0.5 }}>
          or by claude in 10 seconds.
        </div>
      </header>
    </div>
  );
}

export default App;
