import React, { useState } from "react";
import { Loader2 } from "lucide-react";

const ImageUpload = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [roastResult, setRoastResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError("Image size must be less than 5MB");
        return;
      }
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setRoastResult("");
      setError("");
    }
  };

  const uploadImage = async () => {
    if (!selectedImage) {
      setError("Please select an image first");
      return;
    }
    setIsLoading(true);
    setRoastResult("");
    setError("");

    try {
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      reader.onloadend = async () => {
        const base64Data = reader.result.split(",")[1];

        try {
          const response = await fetch(
            "https://asia-southeast1-nomadic-botany-388713.cloudfunctions.net/roast-me",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                image_data: base64Data,
              }),
            }
          );

          const data = await response.json();
          if (!response.ok) {
            throw new Error(data.error || "Failed to get roast");
          }
          setRoastResult(data.roast);
        } catch (err) {
          setError(err.message || "Failed to upload image");
        } finally {
          setIsLoading(false);
        }
      };
    } catch (err) {
      setError("Failed to process image");
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">Get Roasted! 🔥</h2>
      <div className="flex flex-col items-center gap-4">
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleImageSelect}
        />

        {previewUrl && (
          <div className="w-full max-w-md">
            <img
              src={previewUrl}
              alt="Preview"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
        )}

        {error && (
          <div className="text-red-500 text-sm bg-red-50 p-2 rounded-lg">
            {error}
          </div>
        )}

        <button
          onClick={uploadImage}
          disabled={!selectedImage || isLoading}
          className={`px-6 py-2 rounded-lg text-white flex items-center gap-2 ${
            !selectedImage || isLoading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-green-500 hover:bg-green-600"
          }`}
        >
          {isLoading && (
            <Loader2 className="w-4 h-4 animate-spin motion-reduce:animate-spin" />
          )}
          {isLoading ? "Getting Roasted..." : "Roast Me!"}
        </button>

        {isLoading && (
          <div className="flex items-center justify-center gap-2 text-blue-500">
            <Loader2 className="w-8 h-8 animate-spin motion-reduce:animate-spin" />
            <p>AI is thinking of something clever...</p>
          </div>
        )}

        {roastResult && (
          <div className="mt-4 p-4 bg-gray-100 rounded-lg w-full">
            <p className="text-lg">{roastResult}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
